import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-md-12" }
const _hoisted_3 = { class: "card mb-3 card-stretch" }
const _hoisted_4 = { class: "card-footer p-0 ps-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tab_navigate = _resolveComponent("tab-navigate")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.claim?.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_tab_navigate, {
                  items: [
              {id: 'recovery-summary', router: `/claims/` + _ctx.claim.id + '/recovery/summary', name: 'Summary'},
              {id: 'recovery-activities', router: `/claims/` + _ctx.claim.id + '/recovery/activities', name: 'Activities'},
               {id: 'recovery-documents', router: `/claims/` + _ctx.claim.id + '/recovery/documents', name: 'Documents'},
            ]
                }, null, 8, ["items"])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_RouterView)
  ], 64))
}