
import {computed, defineComponent, onMounted, onRenderTracked, onUpdated} from 'vue'
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import store from "@/store";

export default defineComponent({
  name: "Recovery",
  components: {TabNavigate},
  setup() {
    onMounted(() => {
      const link = document?.getElementById("main-overview")

      if (link) {
        console.log("remove link ", link)
        link.classList.remove("active")
      }
    })
    onUpdated(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        console.log("remove link ", link)
        link.classList.remove("active")
      }
    })
    onRenderTracked(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        console.log("remove link ", link)
        link.classList.remove("active")
      }
    })
    const claim = computed(() => store.state.ClaimModule.claim);
    return {
      claim,
    }
  }
})
